import { FC, useEffect } from 'react';

import { useLIFFAccessToken } from '@/hooks/useLIFFSDK';
import DisableOnSSR from '@/c/wrappers/DisableOnSSR';
import LoadingSkeleton from '@/c/common/LoadingSkeleton';
import RequiredLINEAuthWrapper from '@/c/wrappers/RequiredLINEAuthWrapper';

const Redirector: FC<{ destinationLink: string }> = ({ destinationLink }) => {
  useEffect(() => {
    if (!destinationLink) return;
    window.location.href = destinationLink;
  }, [destinationLink]);

  return (<></>);
};

export default function HomePage() {
  const { data: accessToken } = useLIFFAccessToken();

  const destLiffId = process.env.NEXT_PUBLIC_DEST_LIFF_ID;
  const brandCode = process.env.NEXT_PUBLIC_BRAND_CODE;
  const destinationLink = `https://liff.line.me/${destLiffId}/auth?brand_code=${brandCode}&line_token=${accessToken}`;

  return (
    <DisableOnSSR>
      <RequiredLINEAuthWrapper>
        <Redirector destinationLink={destinationLink} />
        <LoadingSkeleton>
          <a className="underline" href={destinationLink}>點這裡馬上跳轉</a>
        </LoadingSkeleton>
      </RequiredLINEAuthWrapper>
    </DisableOnSSR>
  );
}

import { FC, PropsWithChildren } from 'react';

import useLIFFSDK from '@/hooks/useLIFFSDK';

const RequiredLINEAuthWrapper: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { data: liff } = useLIFFSDK();

  if (!liff.isLoggedIn()) {
    liff.login({
      redirectUri: window.location.href,
    });
    return <></>;
  }

  return (<>{children}</>);
};

export default RequiredLINEAuthWrapper;

import { useQuery } from 'react-query';

const useLIFFSDK = () => useQuery(
  ['useLIFFSDK'],
  async () => {
    const liff = (await import('@line/liff')).default;

    await liff.init({
      liffId: process.env.NEXT_PUBLIC_LIFF_ID as string,
    });

    return new Promise<typeof liff>((resolve, reject) => {
      liff.ready
        .then(() => resolve(liff))
        .catch((e) => reject(e));
    });
  },
  {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  },
);

export const useLIFFProfile = () => {
  const { data: liff } = useLIFFSDK();

  return useQuery(
    ['useLIFFProfile', liff?.id],
    async () => {
      const profile = await liff?.getProfile?.();

      return profile;
    },
    {
      enabled: !!liff,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
};

export const useLIFFAccessToken = () => {
  const { data: liff } = useLIFFSDK();

  return useQuery(
    ['useLIFFAccessToken', liff?.id],
    async () => {
      const profile = await liff?.getAccessToken?.();

      return profile;
    },
    {
      enabled: !!liff,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
};

export default useLIFFSDK;
